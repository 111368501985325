import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Grid, Divider, Box} from "@material-ui/core";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import showdown from 'showdown';

// eslint-disable-next-line
export const CountyDownPageTemplate = ({ heading, description, image }) => {
  const converter = new showdown.Converter();
  const pastImage = getImage(image);

  return (
    <>
      <FixedNavbar />
      <section >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="sectionHeaderCenter">
              <h2>{heading}</h2>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={pastImage ? 8 : 12}>
              <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(description) }} />
            </Grid>
            {pastImage && (
              <Grid item xs={12} sm={12} md={4}>
                <Box className="aboutImageContainer">
                  <GatsbyImage placeholder="blurred" image={pastImage} alt={""} className="aboutImage" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
};

CountyDownPageTemplate.propTypes = {
  heading: PropTypes.string.isRequired,
};

const CountyDownPage = ({ data }) => {

  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CountyDownPageTemplate heading={post.frontmatter.heading} description={post.frontmatter.description} image={post.frontmatter.image} />
    </Layout>
  );
};

CountyDownPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CountyDownPage;

export const CountyDownPageQuery = graphql`
  query CountyDownPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "countydown-page"}}) {
      frontmatter {
        heading
        description
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
      }
    }
  }
`;
